import {
  main,
  searchListener,
  generateLiteWidget,
  generateLiteListing,
  computeCols,
  observeNewElements,
  observeCustomElement,
  loadListingsSm,
  updateLiteListing,
  afterInteraction,
  createRemoteSession,
  sendListingAction,
  RemoteSession,
  prevWidths,
} from "./Widget";
import { generateAds } from "./AdStack";
import { ICustomizations } from "./providers/types";
import { getListingUrl } from "./providers/Customizations";
import { lotame } from "./Lotame";
import { generateSingleColumn, generateDualColumn, generateTriColumn, generateQuadColumn } from "./WidgetV2";
import { IEventAttributes, pushEvent, pushEventNow, pushFirstAdViewed } from "./Events";

let sellwildCachedAds: ReturnType<typeof generateAds>
observeCustomElement("sellwild-widget", runWidget)
observeCustomElement("sellwild-ad", runWidgetAd)
main(() => {
  return observeNewElements("sellwild", runWidget)
});

(window as any)['runWidgets'] = () => observeNewElements("sellwild", runWidget)

function runWidgetAd(root: Element, theme: ICustomizations, widgetId: string = '0') {
  if ((window as any).pbjs) {
    (window as any).pbjs.initAdserverSet = false
  }
  const ads = generateAds(theme, {
    prefix: `sellwild-${widgetId}`,
  });
  const width = Number(root.getAttribute('width'))
  const height = Number(root.getAttribute('height'))
  const tag = ads.useGoogleDisplay([width, height])
  ads.initialize()
  root.innerHTML = tag
  ads.appendScripts(document.body)
}

function runWidget(root: Element, theme: ICustomizations, widgetId: string = '0') {
  const rect = root.parentElement && root.parentElement.getBoundingClientRect();
  // if (rect && rect.width === prevWidths[widgetId]) return;
  // prevWidths[widgetId] = rect && rect.width;
  const width = rect ? rect.width : theme.defaultWidth
  const cols = computeCols(
    rect && rect.width > 10 ? rect.width : theme.defaultWidth,
    theme
  );

  const attributes = {
    widgetType: 'display',
    widgetName: theme.name,
    code: theme.partnerCode,
    version: theme.version || 1,
    cols
  }
  pushEventNow({ event: 'view', attributes })

  if (theme.adType === 'PrebidStandalone') {
    const colEls = ((theme as any)[`col${cols}`] || "").split('')
    const count = colEls.length;
    const listingHeight = count === 1 ? colEls.reduce((acc: number, el: string) => acc + (el === 'i' ? 200 : 300), 0) : (count / cols) * (theme.overlayTitle ? 300 : 360)
    const height = listingHeight + (cols === 1 ? 0 : 100) + (theme.title ? 50 : 0);
    const frame = document.createElement('iframe')
    frame.width = '100%'
    frame.frameBorder = 'none'
    frame.scrolling = 'no'
    frame.height = `${height}px`
    frame.srcdoc = `<script src='//widget.sellwild.com/${theme.partnerCode}/${theme.slug}.js'></script><sellwild-widget ad-type='Prebid'></sellwild-widget>`
    root.appendChild(frame)
    return
  }
  const ads = generateAds(theme, {
    prefix: `sellwild-ad-${widgetId}`,
    onBidderDone: () => {
      pushFirstAdViewed({ attributes })
    },
    onAuctionEnd: (winningBids) => {
      for (const bid of winningBids || []) {
        pushEvent({
          event: 'adWinningBid',
          label: bid.adUnitCode,
          action: bid.bidder,
          amount: bid.cpm,
          attributes 
        })
      }
    },
    onAdRenderSucceeded: (event) => {
      pushEvent({
        event: 'adRenderSucceeded',
        label: event.bid.adUnitCode,
        action: event.bid.bidder,
        amount: event.bid.cpm,
        attributes 
      })
    },
    onDirectRenderSucceeded: (event) => {
      pushEvent({
        event: 'directAdRenderSucceeded',
        label: event.bid.adUnitCode,
        action: event.bid.bidder,
        amount: event.bid.cpm,
        attributes 
      })
    }
  });
  ads.useAudigent();

  if (theme.version === 2) {
    const breakpoints = theme && theme.breakpoints || {} as ICustomizations['breakpoints']
    if (width >= (Number(breakpoints.col4) || 1200)) {
      root.innerHTML = generateQuadColumn({ ads, theme })
    } else if (width >= (Number(breakpoints.col3) || 900)) {
      root.innerHTML = generateTriColumn({ ads, theme })
    } else if (width >= (Number(breakpoints.col2) || 600)) {
      root.innerHTML = generateDualColumn({ ads, theme })
    } else {
      root.innerHTML = generateSingleColumn({ ads, theme })
    }
    ads.initialize()
  } else {
    const grid = generateGrid(theme, ads, cols);
    const topbanner = theme.hideBannerTop ? '' : (width > 700 ? ads.useGoogleDisplay([728,90]) || ads.useBidstream(theme.bannerZid,728,90) : "");
    const bottombanner = theme.hideBannerBottom ? '' : (width > 700 ? ads.useGoogleDisplay([728,90]) || ads.useBidstream(theme.bottomBannerZid,728,90) : "");
    root.innerHTML = generateLiteWidget(
      theme,
      topbanner,
      grid,
      bottombanner,
      cols
    );
    ads.initialize()
    searchListener();
  }

  const session: RemoteSession = {};
  const appendListings = () => {
    return loadListingsSm(theme)
      .then(({ listings, config, widgetCacheVersionId }) => {

        ads.appendScripts(document.body).then(v => {
          if (v && v.blocked) {
            pushEvent({ event: 'adsGeoBlocked', action: v.type, label: v.value, attributes })
          }
        })
        
        session.widgetCacheVersionId = widgetCacheVersionId;

        const listingEls = root.querySelectorAll(
          '.sellwild-listing[data-loading="true"]'
        );

        for (const i in listingEls) {
          const listingEl = listingEls[i]
          if (!listingEl) continue
          const listing = listings[Number(i) % listings.length]
          if (!listing) continue
          const url = getListingUrl(listing, config, theme);
          updateLiteListing(listingEl, listing, url, theme);
        }

        createRemoteSession(session);
      })
      .catch((e) => console.log("Listing error", e));
  };

  if (theme.optimizeFcp) {
    const removeListeners = afterInteraction(() => {
      removeListeners();
      appendListings();
    });
  } else {
    appendListings();
  }

  if (root.parentElement) {
    root.parentElement.classList.remove('mx-10', 'mx-8')
    root.parentElement.classList.add('px-4')
  }
  root.addEventListener("click", (ev: any) => {
    const listing = ev.target.closest(".sellwild-listing");
    if (!listing) return;

    const listingId = listing.getAttribute("data-id");
    if (!listingId) return;
    
    const partner = listing.getAttribute("data-partner");

    if (partner === 'lotlinx') {
      pushEvent({ event: 'clickLotlinx', label: listingId, attributes })
    } else {
      pushEvent({ event: 'click', label: listingId, attributes })
    }
  })

  if (theme.lotame) {
    const lotameClientStart = Date.now();
    const lotameClient = lotame.initialize((profile) => {
      const profileId = profile.getProfileId();
      const panoramaId = profile.getPanoramaId();
      session.lotameId = profileId;
      session.panoramaId = panoramaId;
      createRemoteSession(session).catch(e => console.log('Create sess err', e));
    });
  
    lotameClient.cmd.push(() => {
      lotameClient.sync({});
    });
  
    if (theme.membershipType) {
      root.addEventListener("click", (ev: any) => {
        const listing = ev.target.closest(".sellwild-listing");
        if (!listing) return;
    
        const listingId = listing.getAttribute("data-id");
        if (!listingId) return;

        sendListingAction({
          trackingData: {
            userId: session.userId,
            panoramaId: session.panoramaId,
            lotameId: session.lotameId,
          },
          listingId,
          membershipType: theme.membershipType,
        }).catch(e => console.log('Send action err', e));
      });
    }
  }
}

function generateGrid(
  theme: ICustomizations,
  ads: ReturnType<typeof generateAds>,
  cols: number
) {
  switch (cols) {
    case 1:
      return renderItems(ads, theme.col1 || "M0M1M2");
    case 2:
      return renderItems(ads, theme.col2 || "D01D23");
    case 3:
      return renderItems(ads, theme.col3 || "012D3D");
    case 4:
      return renderItems(ads, theme.col4 || "0123D45D");
    case 5:
      return renderItems(ads, theme.col5 || "01234D567D");
    default:
      return renderItems(ads, theme.col6 || "012345D6789D");
  }
}

function renderItems(
  ads: ReturnType<typeof generateAds>,
  colString: string = ""
) {
  return decodeURI(colString)
    .split("")
    .map((type) => renderItem(ads, type))
    .join("");
}

function renderItem (ads: ReturnType<typeof generateAds>, type: string) {
    const n = Number(type)
    if (!isNaN(n)) {
      return `<div class="sellwild-slider">${times(n).map(() => generateLiteListing()).join('')}</div>`
    }
    switch (type) {
        case 'G': return `<div class='sellwild-listing-ad'>${ads.useGoogleDisplay([300,250])}</div>`
        case 'C': return `<div class='sellwild-clear'></div>`
        case 'b': return `<div class='sellwild-listing-ad' style='width: 320px; height: 50px'>${ads.useGoogleDisplay([320,50]) || ads.useMobileBanner()}</div>`
        case 'M': return `<div class='sellwild-listing-ad'>${ads.useGoogleDisplay([300,250]) || ads.useBidstreamDisplay()}</div>`
        case 'D': return `<div class='sellwild-listing-ad'>${ads.useGoogleDisplay([300,250]) || ads.useBidstreamDisplay()}</div>`
        case 'S': return `<div class='sellwild-listing-ad-skyscraper'>${ads.useBidstreamSkyscraper()}</div>`
        case 'Z': return ads.useZipRecruiter(Math.floor(Math.random() * 12) + 1)
        case 'z': return ads.useZipRecruiter(Math.floor(Math.random() * 12) + 1, true)
        case '<': return '<div>'
        case '>': return '</div>'
        case 'i': return generateLiteListing('sellwild-listing-short')
        case 'l':
        case 'I': return generateLiteListing('sellwild-listing-skinny')
        case 'L': return generateLiteListing()
        default: return ''
    }
}

function times (n = 0) {
  let arr = []
  for (let i = 0; i < n; i++) {
    arr.push(i)
  }
  return arr
}